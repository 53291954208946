<template>
  <div style="max-width: 800px;margin:0 auto;">

    <div class="my-5" style="">
      <p>
        下記の「ご利用にあたって」をご確認いただき、購入様確認用の質問にご回答の上、CBT体験模試をご利用ください。<br>
        <br>
        【ご利用にあたって】<br>
        本アプリは FP２級 合格のトリセツ 2024-25年版 のご購入者様に限りご利用いただけます。本書籍購入者様以外はご利用いただけません。<br>
        <br>
        １．目　的<br>
        本アプリは、制限時間内にＰＣ上で学科試験問題を解く練習のための試験プログラムです。<br>
        ※本試験とは同一ではありませんので、あらかじめご了承ください。<br>
        <br>
        ２．動作環境<br>
        本アプリは、ＰＣ上でご利用ください。他の端末では問題等が正しく表示されません。<br>
        <br>
        【推奨環境】<br>
        ・Google Chrome（最新版）<br>
        ・Microsoft Edge（最新版）<br>
        ・Firefox（最新版）<br>
        ・Safari （最新版）<br>
        <br>
        ３．配信期限<br>
        <strong>2025年５月31日まで</strong>

      </p>
      <p>上記内容に同意された方は、以下の購入様確認用の質問にご回答のうえ、本アプリをご利用ください。</p>
    </div>
    <div>
      <div>
        <div class="mb-5">
          <h5>ご購入の書籍</h5>
          <select class="form-control" v-model="currentBook" @change="selectedBook">
            <option value="0" disabled default>-</option>
            <option v-for="b in books" :value="b.val" v-if="isDisplay(b.val)">{{ b.name }}</option>
          </select>
        </div>

        <div v-if="qas.q != ''">
          <div class="question-box">
            <div class="strg">{{ qas.q }}</div>
          </div>
          <div class="my-3 text-center" style="">
            <input type="text" class="form-control"
              style="max-width: 300px;margin: 0 auto; border:solid 1px #203E7B;text-align: right;" v-model="answer"
              placeholder="答え" />
          </div>

          <div class="text-center mb-5">
            <button class="btn btn-primary D4ckn6yw" style="font-size: 18px" @click="answerQa">回答を送信</button>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>


<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import BasicUtil from "../services/basicUtil";
@Component({
  components: { Header }
})
export default class AuthView extends Vue {
  private qas: any = { q: "", a: "" };
  private answer: string = "";
  private isCheck: boolean = false;
  private isError: boolean = false;
  private books: any[] = [
    { name: "速習テキスト", val: 1 },
    { name: "速習問題集", val: 2 },
    { name: "過去問厳選模試", val: 3 },
  ]
  private currentBook: number = 0

  created() {
    document.getElementById("body")!.classList.add("auth-body");
    this.$nextTick(function () {
      // this.qas = BasicUtil.getQa3(this.currentBook);
    });
  }

  selectedBook() {
    this.answer = ""

    this.$nextTick(function () {
      this.qas = BasicUtil.getQa3(this.currentBook);
    });
  }
  private answerQa() {
    this.isError = false
    if (BasicUtil.zentoHanNumber(this.answer) != this.qas.a) {
      this.isError = true
      return;
    }
    switch (this.currentBook) {
      case 1:
        BasicUtil.setGradePass("3");
        break
      case 2:
        BasicUtil.setGradePass("3");
        break
      case 3:
        BasicUtil.setGradePass("33");
        break
    }

    location.href = "/"
  }

  private isDisplay(bookId: number) {
    if (bookId == 1 || bookId == 2) {
      return !BasicUtil.isAuth("3")
    }

    if (bookId == 3) {
      return !BasicUtil.isAuth("33")
    }
  }
}
</script>


export default [
{
  "id": 1,
  "name": "学科試験",
  "no": 1,
  "grade": 2,
  "time": 90
},
{
  "id": 2,
  "name": "資産設計提案業務",
  "no": 2,
  "grade": 2,
  "time": 90
},
{
  "id": 3,
  "name": "個人資産相談業務",
  "no": 3,
  "grade": 2,
  "time": 90
},
{
  "id": 4,
  "name": "保険顧客資産相談業務",
  "no": 4,
  "grade": 2,
  "time": 90
},
]
<template>
  <div class="r6TtFRdJ2">
    <div class="main2">
      <h3>{{ getShikenTitle() }}</h3>
      <div class="d-flex resultview align-items-center mt-4">

        <div style="width: 200px">合計得点</div>
        <div style="flex:1"><span class="score">{{ score }}</span>点</div>
        <div class="">
          <span v-if="isPass" class="pass">合格</span>
          <span v-else class="pass">不合格</span>
        </div>
      </div>

      <div class="mt-3">
        <h3 style="font-size: 1.1em;">解答一覧</h3>
        <div v-if="shikenId == 1" class="row">
          <div class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i < 20"
                :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
                <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
          <div v-if="shikenType == 1" class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 20 && i < 40"
                :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
                <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
          <div v-if="shikenType == 1" class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 40"
                :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
                <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
        </div>
        <!-- 学科以外 -->
        <div v-else class="row">
          <div class="col-6">
            <table class="listTable">
              <tr>
                <td style="width: 60px">問題</td>
                <td class="text-center" style="width: 250px">あなたの解答</td>
                <td class="text-center" style="width: 250px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i < 20">
                <td class="text-center">{{ v.no }}</td>
                <td v-if="v.currentChoiceArr != undefined && v.currentChoiceArr.length > 0" class="p-0">
                  <div v-for="(c, ci) in v.currentChoiceArr" :class="{ 'sZ5iBCme': !isCorrectJitsugi(v, ci) }" class="px-2">
                    <span>{{ getSubNumer(ci, v) }} </span>
                    {{ getChoiceString(v, ci) }}　
                  </div>
                </td>
                <td v-else  :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                  {{ getChoiceString(v) }}
                </td>
                <td v-if="isString(v.answer) && v.answer.includes(',')" >
                  <div v-for="(c, ci) in v.answer.split(',')">
                    <span>{{ getSubNumer(ci, v) }} </span>
                    {{ getAnswerString(v, ci) }}
                  </div>
                </td>
                <td v-else>
                  {{ getAnswerString(v) }}
                </td>
              </tr>
            </table>
          </div>
          <div v-if="questions.length > 20" class="col-6">
            <table class="listTable">
              <tr>
                <td style="width: 60px">問題</td>
                <td class="text-center" style="width: 250px">あなたの解答</td>
                <td class="text-center" style="width: 250px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 20 && i < 40">
                <td class="text-center">{{ v.no }}</td>
                <td v-if="v.currentChoiceArr != undefined && v.currentChoiceArr.length > 0" class="p-0">
                  <div v-for="(c, ci) in v.currentChoiceArr" :class="{ 'sZ5iBCme': !isCorrectJitsugi(v, ci) }" class="px-2">
                    <span>{{ getSubNumer(ci, v) }} </span>
                    {{ getChoiceString(v, ci) }}
                  </div>
                </td>
                <td v-else  :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                  {{ getChoiceString(v) }}
                </td>
                <td v-if="isString(v.answer) && v.answer.includes(',')" >
                  <div v-for="(c, ci) in v.answer.split(',')">
                    <span>{{ getSubNumer(ci, v) }} </span>
                    {{ getAnswerString(v, ci) }}　
                  </div>
                </td>
                <td v-else>
                  {{ getAnswerString(v) }}
                </td>
              </tr>
            </table>
          </div>
          <!-- <div v-if="shikenType == 1" class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 20 && i < 40"
                :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
                <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div>
          <div v-if="shikenType == 1" class="col-4">
            <table class="listTable">
              <tr>
                <td>問題</td>
                <td class="text-center" style="width: 120px">あなたの解答</td>
                <td class="text-center" style="width: 120px">正解</td>
              </tr>
              <tr v-for="(v, i) in questions" :key="v.id" v-if="i >= 40"
                :class="{ 'sZ5iBCme': v.currentChoice != v.answer }">
                <td class="text-center">{{ v.no }}</td>
                <td class="text-center">{{ getChoiceString(v) }}</td>
                <td class="text-center">{{ getAnswerString(v) }}</td>
              </tr>
            </table>
          </div> -->
        </div>
      </div>
    </div>
    <div class="footer">
      <hr class="m-0" />
      <div class="hB6kQJPA align-items-center">
        <div class="text-end">
          <a v-if="shikenId < 7" :href="'/pdf/l' + String(shikenId) + '.pdf'" target="_blank"
            class="btn btn-primary D4ckn6yw Gb7qXjW3 mx-3 mt-3" style="width:200px">解答・解説PDF</a>
          <button @click="endExam" class="btn btn-primary D4ckn6yw Gb7qXjW3 mx-3 mt-3" style="width:200px">終了</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import Question from "../models/question";
import StorageService from "../services/storage";
import BasicUtil from "../services/basicUtil";
import Shiken from "../models/shiken";
import HttpClient from "../services/httpClient";

@Component({
  components: {}
})
export default class ResultView extends Vue {
  public questions: Question[] = [];
  private grade: number = 3;
  private all: number = 0;
  private score: number = 0;
  private scores: number[] = [0, 0, 0, 0, 0, 0]
  private isPass: boolean = false;
  private shikenId: number = 0
  private shikenType: number = 1
  private shikenName: string = ""
  created() {
    history.pushState(null, "", null);
    this.questions = this.$store.state.data;
    if (this.questions.length == 0) return (location.href = "/");

    const question = this.questions[0];
    const shiken: Shiken | null = new HttpClient().getShiken(
      Number(question.shikenId)
    );
    if (shiken == null) return (location.href = "/");
    this.shikenId = shiken.id
    this.shikenName = shiken.name
    this.shikenType = question.answerType
    this.grade = shiken.grade;
    const vm = this;
    this.questions.forEach((q: Question) => {

      if (this.isString(q.score) && q.score.includes(",")) {
        let scores = q.score.split(",")
        let si = 0
        scores.forEach(s => {
          vm.all += Number(s);
          if (q.results[si]) vm.score += Number(s);
          si += 1
        })

        if (q.currentChoiceArr.length == 0) {
      const results = []
      for (let i = 0; i < scores.length; i++) {
        q.currentChoiceArr.push("")
        results.push(false)
      }
      q.results = results
    }
      } else {
        vm.all += Number(q.score);
        if (q.result == undefined && q.results.length > 0) {
          if (q.results[0]) vm.score += Number(q.score);
        }
        else {
          if (q.result) vm.score += Number(q.score);
        }
        
      }

    });
    if (this.score >= this.all * 0.6) {
      this.isPass = true;
    }
    console.log(this.questions)
  }
  mounted() { }
  getShikenTitle() {
    return this.shikenName == "学科試験" ? "学科試験" : "実技試験 " + this.shikenName
  }
  close() {
    location.href = `/`
  }

  isString(value: any) {
    return typeof value === 'string';
}

  isIncorrect(question: Question | undefined) {
    if (question == undefined) return false;
    return !question.result;
  }


  getDisplayAnswer(num: number, question: Question) {
    if (question.choice1 == "○" && question.choice2 == "✕") {
      if (num == 1) return "○"
      if (num == 2) return "×"
      return "-"
    }
    return num
  }


  private endExam() {

    if (!window.confirm("終了しますか？")) return
    return location.href = "/"
  }

  getChoiceString(q: Question, i: number = 0) {
    if (q.currentChoice == undefined && q.currentChoiceArr == undefined) return ""
    if (q.currentChoiceArr != undefined && q.currentChoiceArr.length > 0) {
      q.currentChoiceArr[i] = this.numberFormat2(q.currentChoiceArr[i])
      if (q.answerType == 6) { // 選択問題の場合
        return q.currentChoiceArr[i] == "" ? "" : q.currentChoiceArr[i].charAt(0)
      }
      if (q.answerType == 8) { // 選択問題の場合
        return q.currentChoiceArr[i]
      }
      if (q.answerType == 5 || q.answerType == 7) { // マルバツ
        return q.currentChoiceArr[i] == "" ? "" : q.currentChoiceArr[i] == "1" ? "〇" : "×"
      }
      return q.currentChoiceArr[i] == "" ? "" : q.currentChoiceArr[i]
    } else {
      if (q.choice1 == "〇") {
        return q.currentChoice == 1 ? "〇" : "×"
      }
      if (q.currentChoice == undefined) return ""
      return String(q.currentChoice)
    }


  }

  isNumber(value: any) {
    return typeof value === 'number' && !Number.isNaN(value);
}

  getAnswerString(q: Question, i: number = 0) {
    if (q.answer == undefined) return ""
    if (this.isString(q.answer) && q.answer.includes(",")) {
      let answers = q.answer.split(",")
      answers[i] = this.numberFormat2(answers[i])
      
      if (q.answerType == 5 || q.answerType == 7) { // マルバツ
        return answers[i] == "1" ? "〇" : "×"
      }
      return answers[i]
    } else {
      if (q.choice1 == "〇") {
        return q.answer == "1" ? "〇" : "×"
      }
      if (this.isNumber(q.answer)) {
        return Number(q.answer).toLocaleString();
      }
      return q.answer
    }
  }

  numberFormat2(val: string) {
    const numberRegex = /^\d+$/;
      // 入力が数字のみか確認
      if (numberRegex.test(val)) {
        // カンマ区切りのフォーマットに変換
        val = Number(val).toLocaleString();
      } 
      return val
  }

  getSubNumer(num: number, q: Question) {
    
    let isOnlyOne = false
    if (q.answerType == 9) {
      const answers = String(q.answer).split(",")
      isOnlyOne = answers.length == 1
    }
    if (isOnlyOne) return "";
    if (q.groupId == 0)
    {
switch (num) {
      case 0: return "①"
      case 1: return "②"
      case 2: return "③"
      case 3: return "④"
    }
    } else if (q.groupId == 1) {
      switch (num) {
      case 0: return "(ア)"
      case 1: return "(イ)"
      case 2: return "(ウ)"
      case 3: return "(エ)"
    }
    }
    
    return ""
  }

  isCorrectJitsugi(q: Question, i: number)
  {
    try {
      if (String(q.answer).includes(",")) {
        let answers = q.answer.split(",")
        let answer = answers[i]
        let choice = q.currentChoiceArr[i]
        answer = this.numberFormat2(answer)
        choice = this.numberFormat2(choice)
        if (q.answerType == 6) { // 選択
        return answer == choice.charAt(0)
      }
      if (q.answerType == 8) { // 選択
        return answer == choice
      }
      
      return answer == choice 
      } else {
        if (q.currentChoice == undefined)
      {
        let choice = q.currentChoiceArr[i]
        let answer = this.numberFormat2(q.answer)
        choice = this.numberFormat2(choice)
        return answer == choice 
      }
        return q.currentChoice == Number(q.answer)
      }
      
    } catch (e) {
      console.log(e)
    }
    return false
  }
}
</script>

<template>
  <div class="wrap">
    <div class="left-wrap">
      <div class="" v-if="question.choice5 != ''">-{{ question.choice5 }}-</div>
      <p :class="'pi-' + question.id" v-html="question.question1"></p>
    </div>
    <div class="center-border"></div>
    <div class="right-wrap">
      <div class="mb-3">
        <div class="G6zckbvr " :class="{ 'eJ7LMsiQ': questions[index].isBookmark }"></div>問{{ question.no }}
      </div>
      <p :class="'qi-' + question.id" v-html="question.question2"></p>
      <ol class="Ar2jysLK" :class="{ 'Gm8W5aZb': questions[index].isBookmark }" v-if="questions[index].currentChoiceArr.length > 0">
        <li v-for="(answer, i) in answers" :key="i">
          <div class="d-flex align-items-center">
            <span v-if="!isOnlyOne">{{ getIndexStr(i) }}</span>
            <input class="text-r mr-3" type="text" @change="answerExe(i)" @focus="rmComma" @blur="onlyNumber($event, i)" v-model="questions[index].currentChoiceArr[i]">
            <span v-if="i == 0">{{ questions[index].choice1 }}</span>
            <span v-if="i == 1">{{ questions[index].choice2 }}</span>
            <span v-if="i == 2">{{ questions[index].choice3 }}</span>
            <span v-if="i == 3">{{ questions[index].choice4 }}</span>
          </div>
        </li>
      </ol>
      <div style="height: 250px;"></div>
    </div>


  </div>
</template>


<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Question from "../../models/question";
import StorageService from "../../services/storage";
import question from "@/services/question";
import BasicUtil from "@/services/basicUtil";
@Component({
  components: {}
})
export default class Double extends Vue {
  @Prop()
  private question!: Question;
  @Prop()
  private index!: number;
  @Prop()
  private indexs!: number[];
  @Prop()
  public questions!: Question[]


  private answers: string[] = []

  private isLock: boolean = false;
  private isOnlyOne: boolean = false
  mounted() {
    this.answers = String(this.questions[this.index].answer).split(",")
    if (this.questions[this.index].currentChoiceArr.length == 0) {
      const results = []
      for (let i = 0; i < this.answers.length; i++) {
        this.questions[this.index].currentChoiceArr.push("")
        results.push(false)
      }
      this.questions[this.index].results = results
      this.isOnlyOne = results.length == 1
    }

      for (let i = 0; i < this.questions[this.index].currentChoiceArr.length; i++) {
        if (this.questions[this.index].currentChoiceArr[i].length > 0 && !this.questions[this.index].currentChoiceArr[i].includes(",")) this.questions[this.index].currentChoiceArr[i] = Number(this.questions[this.index].currentChoiceArr[i]).toLocaleString()
      }
  }


  @Emit("answerEmit")
  answerEmit() {
    return
  }

  public async answerExe(i: number) {
    this.questions[this.index].results[i] = this.answers[i] == this.questions[this.index].currentChoiceArr[i].replace(/[^0-9.]/g, '');
    this.$store.commit("setQuestions", this.questions);
    this.answerEmit();
  }

  onlyNumber(e: any, i: number) {
      e.target.value = new BasicUtil().onlyNumberMinus(e.target.value)
      this.questions[this.index].currentChoiceArr[i] = String(e.target.value)
    }
    rmComma(e: any) {
      e.target.value = String(e.target.value).replace(/,/g, "")
    }

    getIndexStr(i: number) {
    if (this.questions[this.index].groupId == 0) {
      return String(i + 1)
    }
    else {
      let kana = ["ア", "イ", "ウ", "エ", "オ"]
      return "(" + kana[i] + ")"
    }
  }
}
</script>

<style scoped>
.wrap {
  margin: -1.5em !important;
  height: calc(100vh - 120px - 90px);
  display: flex;
  align-items: stretch;

}

.right-wrap {
  width: calc(30% - 1px);
  padding: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
}

.center-border {
  width: 2px;
  background-color: #ccc;
  height: 100%;
}

.left-wrap {
  width: calc(70% - 1px);
  padding: 1.5em;
  overflow-y: scroll;
}
.text-r {
  text-align: right;
}
</style>
<style>
.left-wrap img {
  width: 100% !important;
}

.right-wrap img {
  max-width: 100%;
}
</style>
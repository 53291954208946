<template>
<div class="py-5"  style="max-width: 800px;margin:0 auto;">
  <h6>《注意事項》</h6>
  <ol>
    <li>試験時間は、<strong>学科試験 90分、実技試験60分</strong>です。試験時間が経過すると自動的に終了します。
</li>
    <li>「解く」ボタンをクリックすると試験がスタートします。試験画面には残り時間が表示されます。
</li>
    <li>試験問題については、特に指示のない限り2024年４月１日現在施行の法令等に基づいて解答してください。なお、東日本大震災の被災者等に対する各種特例等につ いては考慮しないものとします。
</li>
<li>試験中は「<strong>次へ</strong>」「<strong>前へ</strong>」ボタンで、問題画面を進めてください。<br>ブラウザの戻る・進むボタンで画面を移動すると、エラーとなりますのでご注意ください。
</li>
    <li>画面下の「<strong>解答状況</strong>」ボタンをクリックすると、未解答となっている問題や「<strong>後で見直す</strong>」ボタンでチェックした問題が確認できます。
</li>
<li>計算が必要な問題は、画面下の「電卓」ボタンをクリックし、ＰＣ上に表示された電卓機能をご利用ください。</li>
    <li>試験問題は「<strong>試験終了</strong>」ボタンをクリック、または制限時間になると試験が終了し、試験結果が表示されます。<br>※この機能は本試験にはありません。復習にお役立てください。
</li>

  </ol>
<div class="mt-5">
  <table class="top-list" v-if="isAuth || isAuth2">
    <tr>
      <th>CBT 体験模試</th>
      <th style="width: 150px"></th>
    </tr>
    <tr>
      <td>学科試験</td>
      <td class="text-center"><button @click="exec(1)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
    <tr>
      <td>実技試験［日本FP協会］資産設計提案業務</td>
      <td class="text-center"><button @click="exec(2)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
    <tr>
      <td>実技試験［金財］ 個人資産相談業務</td>
      <td class="text-center"><button @click="exec(3)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
    <tr>
      <td>実技試験［金財］ 保険顧客資産相談業務</td>
      <td class="text-center"><button @click="exec(4)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
  </table>
  <!-- <table class="top-list mt-4" v-if="isAuth2">
    <tr>
      <th>過去問厳選模試 チャレンジ１</th>
      <th style="width: 150px"></th>
    </tr>
    <tr>
      <td>学科試験</td>
      <td class="text-center"><button @click="exec(7)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
    <tr>
      <td>実技試験［日本FP協会］資産設計提案業務</td>
      <td class="text-center"><button @click="exec(8)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
    <tr>
      <td>実技試験［金財］ 個人資産相談業務</td>
      <td class="text-center"><button @click="exec(9)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
    <tr>
      <td>実技試験［金財］ 保険顧客資産相談業務</td>
      <td class="text-center"><button @click="exec(10)" class="btn btn-primary D4ckn6yw">解く</button></td>
    </tr>
  </table> -->
  <!-- <div class="" v-else>
    <a href="/auth" class="btn btn-primary D4ckn6yw Gb7qXjW3 mt-4" style="width:200px">過去問厳選模試認証へ</a>
  </div> -->
</div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "../services/httpClient";
import BasicUtil from "../services/basicUtil";
import StorageService from "../services/storage";


@Component({
  components: {}
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService();
  private isAuth: boolean = false;
  private isAuth2: boolean = false;

  created() {
    this.isAuth = BasicUtil.isAuth("3");
    this.isAuth2 = BasicUtil.isAuth("33");
    if (!this.isAuth && !this.isAuth2) {
      this.$router.push("/auth");
    }
  }
  mounted() {
    new BasicUtil().removeBasicSetting();
    new StorageService().removeCurrentData();
  }

  private exec(shikenId: number) {
    const datas = new HttpClient().getQuestionsFromAnswerType(shikenId)
    const shiken = new HttpClient().getShiken(shikenId)
    if (shiken == null) return
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    this.$store.commit("setIsRe", false)
    this.$store.commit("setGoal", new Date().getTime() + shiken.time * 60 * 1000);
    new BasicUtil().setBasicSetting();
    this.$router.push(`/exam/${shikenId}/1`);
  }
}
</script>
<style scoped>
h3 {
  font-size: 1.1em;
}

table.top-list {
        width: 600px; 
      }
      table.top-list th {
        border: solid 1px #668BCD;
        text-align: center;
        padding: 0.2em 1em;
      }
      table.top-list td {
        border: solid 1px #668BCD;
        padding: 0.2em 1em;
      }
</style>

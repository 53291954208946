<template>
  <div class="wrap">
    <div class="left-wrap">
      <div class="">問　-{{ question.choice5 }}-</div>
      <p :class="'pi-'+question.id" v-html="question.question1"></p>
      <ol class="Ar2jysLB" :class="{'Gm8W5aZb': questions[index].isBookmark}">
      <li v-for="n in 4" :key="n">
        <div v-if="question['choice' + String(n)] && question['choice' + String(n)] != ''">
          <label class="d-flex align-items-start">
            <div class="Ki9LFtxd" v-html="question['choice' + String(n)]"></div>
          </label>
        </div>
      </li>
    </ol>
    </div>
    <div class="center-border"></div>
    <div class="right-wrap">
      <div class="mb-3"><div class="G6zckbvr " :class="{'eJ7LMsiQ': questions[index].isBookmark}"></div>問{{question.no}}</div>
    <ol class="Ar2jysLB" :class="{'Gm8W5aZb': questions[index].isBookmark}">
      <li v-for="n in 4" :key="n">
        <div>
          <label class="d-flex align-items-start">
            <input class="" type="radio" name="select" @change="answer(n)" :checked="question['currentChoice'] == n">
          </label>
        </div>
      </li>
    </ol>
      <div style="height: 250px;"></div>
    </div>


  </div>
</template>


<script lang="ts">
import { Component, Vue, Emit, Prop } from "vue-property-decorator";
import Question from "../../models/question";
import StorageService from "../../services/storage";
import question from "@/services/question";
@Component({
  components: {}
})
export default class Double2 extends Vue {
  @Prop()
  private question!: Question;
  @Prop()
  private index!: number;
  @Prop()
  private indexs!: number[];
  @Prop()
  public questions!: Question[]

  private isLock: boolean = false;
  mounted() { }


  @Emit("answerEmit")
  answerEmit() {
    return
  }

  public async answer(i: number) {
    this.questions[this.index].result = this.question.answer  == String(i);
    this.questions[this.index].currentChoice = i;
    this.$store.commit("setQuestions", this.questions);
    this.answerEmit();
  }
}
</script>

<style scoped>
.wrap {
  margin: -1.5em !important;
  height: calc(100vh - 120px - 90px);
  display: flex;
  align-items: stretch;

}

.right-wrap {
  width: calc(30% - 1px);
  padding: 1.5em;
  padding-left: 1em;
  padding-right: 1em;
  overflow-y: scroll;
}

.center-border {
  width: 2px;
  background-color: #ccc;
  height: 100%;
}

.left-wrap {
  width: calc(70% - 1px);
  padding: 1.5em;
  overflow-y: scroll;
}


</style>
<style>
.left-wrap img {
  width: 100% ;
}
</style>
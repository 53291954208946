import Shiken from "./shiken";

export default class Question {
    public id: number = 0;
    public shikenId: number = 0
    public shiken: Shiken|null = null
    public no: number = 0
    /// 1が択一、2以上がvue使用
    public answerType: number = 0
    // vue使用の場合のグルーピング
    public groupId: number = 0
    /// マルバツの問題文
    public question1: string = ""
    /// マルバツの問題文
    public question2: string = ""
    public choice1: string = ""
    public choice2: string = ""
    public choice3: string = ""
    public choice4: string = ""
    public choice5: string = ""
    /// 1:○2：×、1~4選択肢
    public answer: string = ""
    public score: string = ""

    public result: boolean = false
    public results: boolean[] = []
    public isBookmark: boolean = false
    public currentChoice: number = 0
    public currentChoiceArr: any[] = []
    public important: string = ""
    public categoryName: string = ""
    public rontenName: string = ""
}